<template>
  <div class="mb-10">
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div v-if="!loading && dataSet">
      <div class="px-5">
        <bullet
          :goBack="-2"
          :canBack="true"
          :colorIcon="'#052633'"
          :title="'Automatic Invoice Event'"
        />
        <div class="parent">
          <div class="mt-4">
            <h4 class="not_bold">Nama Pelanggan</h4>
            <v-select
              v-if="customer.length > 0"
              placeholder="Silakan pilih nama pelanggan di sini"
              outlined
              readonly
              filled
              hide-details
              :items="customer"
              item-text="nama_lengkap"
              item-value="id"
              v-model="field.nama"
              dense
              class="rounded-lg"
              @change="checkNamaClient(field.nama)"
            ></v-select>
            <v-text-field
              v-if="customer.length == 0"
              v-model="field.nama"
              placeholder="Silakan pilih nama pelanggan di sini"
              hide-details
              dense
              outlined
              class="rounded-lg"
            ></v-text-field>
          </div>
          <div class="mt-4 d-flex align-center">
            <v-row no-gutters>
              <v-col cols="12" md="4" class="pr-1">
                <h4 class="not_bold">Tanggal Transaksi</h4>
                <v-menu
                  v-model="menuTransaksi"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="field.tgl_transaksi"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Tanggal transaksi"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="field.tgl_transaksi"
                    @input="closeDateMenu(field.tgl_transaksi, 'tgl_transaksi')"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="px-1">
                <h4 class="not_bold">Tanggal Jatuh Tempo</h4>
                <v-menu
                  v-model="menuJatuh"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="field.tgl_jatuh"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Tanggal Jatuh Tempo"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="field.tgl_jatuh"
                    @input="closeDateMenu(field.tgl_jatuh, 'tgl_jatuh')"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <div>
        <v-simple-table
          class="mt-4 pb-2 rounded"
          style="overflow: hidden; border: 1px solid grey"
        >
          <template v-slot:default>
            <thead style="background: #052633">
              <tr>
                <th class="text-left" style="color: white; width: 19%">
                  Layanan
                </th>
                <th class="text-left" style="color: white; width: 11%">
                  Jenis
                </th>
                <th class="text-left" style="color: white; width: 1%">
                  Kuantitas
                </th>
                <th class="text-left" style="color: white; width: 30%">
                  Harga Satuan
                </th>
                <th class="text-left" style="color: white; width: 1%">
                  Diskon
                </th>
                <th class="text-left" style="color: white; width: 15%">
                  Pajak
                </th>
                <th class="text-left" style="color: white; width: 25%">
                  Jumlah
                </th>
                <th class="text-left" style="color: white"></th>
              </tr>
            </thead>
            <tbody style="background: white">
              <tr v-for="(item, n) in itemsInvoice" :key="n">
                <td>
                  <v-text-field
                    filled
                    readonly
                    v-model="item.layanan"
                    placeholder="Silakan isi layanan"
                    hide-details
                    dense
                    class="my-2 rounded-lg"
                    outlined
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    style="min-width: 80px"
                    class="my-2 rounded-lg"
                    v-model="item.jenis"
                    placeholder="Tulis jenis"
                    hide-details
                    dense
                    outlined
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    style="width: 80px"
                    class="my-2 rounded-lg"
                    @blur="countSum(n)"
                    v-model="item.kuantitas"
                    placeholder="Tulis kuantitas"
                    hide-details
                    dense
                    outlined
                    type="number"
                  ></v-text-field>
                </td>
                <td>
                  <div
                    class="rounded-lg my-2 d-flex align-center"
                    style="
                      height: 70%;
                      border: 1px solid grey;
                      overflow: hidden;
                    "
                  >
                    <v-card
                      rounded="0"
                      class="px-2 d-flex align-center justify-center"
                      flat
                      color="#052633"
                      style="width: 30px; height: 100%"
                    >
                      <v-icon small color="white">Rp.</v-icon>
                    </v-card>
                    <currency-input
                      @change="countSum(n)"
                      class_temp="rounded-0"
                      class="pa-2"
                      style="height: 100%; width: 100%"
                      v-model="item.harga_satuan"
                      :placeholder="'Tulis harga satuan'"
                      :options="{
                        currency: 'IDR',
                        currencyDisplay: 'hidden',
                        hideCurrencySymbolOnFocus: false,
                        hideGroupingSeparatorOnFocus: true,
                        hideNegligibleDecimalDigitsOnFocus: true,
                        autoDecimalDigits: true,
                        autoSign: true,
                        useGrouping: true,
                        accountingSign: false,
                        precision: 0,
                        locale: 'in',
                        valueRange: { min: 0, max: 999999999999 },
                      }"
                    />
                  </div>
                </td>
                <td>
                  <v-text-field
                    style="width: 80px"
                    class="my-2 rounded-lg"
                    @blur="countSum(n)"
                    outlined
                    v-model="item.diskon"
                    placeholder="Tulis diskon"
                    hide-details
                    dense
                    type="number"
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    style="max-width: 120px"
                    class="my-2 rounded-lg"
                    placeholder="Pajak"
                    outlined
                    hide-details
                    :items="optPajak"
                    item-text="text"
                    item-value="value"
                    v-model="item.pajak"
                    dense
                    @change="countSum(n)"
                  ></v-select>
                </td>
                <td>
                  <div
                    class="my-2 rounded-lg d-flex align-center"
                    style="
                      height: 70%;
                      border: 1px solid grey;
                      overflow: hidden;
                    "
                  >
                    <v-card
                      rounded="0"
                      class="px-2 d-flex align-center justify-center"
                      flat
                      color="#052633"
                      style="width: 30px; height: 100%"
                    >
                      <v-icon small color="white">Rp.</v-icon>
                    </v-card>
                    <currency-input
                      :disabled="true"
                      @change="countSum(n)"
                      class_temp="rounded-0"
                      class="pa-2"
                      style="height: 100%; width: 100%"
                      :placeholder="'Tulis jumlah'"
                      v-model="item.jumlah"
                      :options="{
                        currency: 'IDR',
                        currencyDisplay: 'hidden',
                        hideCurrencySymbolOnFocus: false,
                        hideGroupingSeparatorOnFocus: true,
                        hideNegligibleDecimalDigitsOnFocus: true,
                        autoDecimalDigits: true,
                        autoSign: true,
                        useGrouping: true,
                        accountingSign: false,
                        precision: 0,
                        locale: 'in',
                        valueRange: { min: 0, max: 999999999999 },
                      }"
                    />
                  </div>
                </td>
                <td>
                  <v-btn
                    @click="removeArray(n)"
                    x-small
                    dark
                    depressed
                    color="#052633"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="mt-4 px-3">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <div class="pa-2">
              <h4 class="not_bold">Notes</h4>
              <v-textarea
                rows="4"
                auto-grow
                v-model="field.notes"
                placeholder="Isi note disini"
                hide-details
                dense
                outlined
                class="rounded-lg"
              ></v-textarea>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="pa-2">
              <div class="d-flex justify-space-between">
                <h4>Subtotal</h4>
                <h4>Rp. {{ formatRupiah(field.total) }}</h4>
              </div>
              <div class="d-flex justify-space-between">
                <h4>Diskon per baris</h4>
                <h4>Rp. {{ formatRupiah(field.total_diskon) }}</h4>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex align-center" style="height: 100%">
                  <h4>Diskon</h4>
                  <div
                    class="mx-1 pa-0 d-flex align-center rounded-lg"
                    style="border: 1px solid grey; overflow: hidden"
                  >
                    <v-btn
                      class="rounded-0"
                      depressed
                      :dark="persen_diskon ? true : false"
                      @click="changePersen('diskon', true)"
                      :style="
                        persen_diskon
                          ? 'background:#052633;'
                          : 'background:white'
                      "
                    >
                      <v-icon small>%</v-icon>
                    </v-btn>
                    <v-btn
                      class="text-capitalize rounded-0"
                      depressed
                      :style="
                        !persen_diskon
                          ? 'background:#052633'
                          : 'background:white'
                      "
                      :dark="persen_diskon ? false : true"
                      @click="changePersen('diskon', false)"
                    >
                      <v-icon small>Rp.</v-icon>
                    </v-btn>
                  </div>
                  <v-text-field
                    v-if="persen_diskon"
                    style="width: 60px; background: white"
                    outlined
                    class="rounded-lg"
                    hide-details
                    dense
                    type="number"
                    v-model="field.diskon_tambahan"
                    @blur="countSum(-2)"
                    @change="countSum(-2)"
                  ></v-text-field>
                  <currency-input
                    @change="countSum(-2)"
                    v-if="!persen_diskon"
                    class_temp="rounded-lg"
                    class="rounded-lg pa-2"
                    style="height: 4vh; border: 1px solid grey"
                    v-model="field.diskon_tambahan"
                    :options="{
                      currency: 'IDR',
                      currencyDisplay: 'hidden',
                      hideCurrencySymbolOnFocus: false,
                      hideGroupingSeparatorOnFocus: true,
                      hideNegligibleDecimalDigitsOnFocus: true,
                      autoDecimalDigits: true,
                      autoSign: true,
                      useGrouping: true,
                      accountingSign: false,
                      precision: 0,
                      locale: 'in',
                      valueRange: { min: 0, max: 999999999999 },
                    }"
                  />
                </div>
                <h4>Rp. {{ formatRupiah(field.diskon_tamhahan_txt) }}</h4>
              </div>
              <div class="d-flex justify-space-between">
                <h4>PPN</h4>
                <h4>Rp. {{ formatRupiah(field.sum_pajak) }}</h4>
              </div>
              <v-divider class="my-4"></v-divider>
              <!-- <div class="d-flex justify-space-between">
                <h4>Total</h4>
                <h4>Rp. {{ field.total_before_cut }}</h4>
              </div>
              <div class="d-flex justify-space-between flex-column">
                <v-checkbox
                  color="#052633"
                  class="ma-0"
                  v-model="field.isCut"
                  label="Pemotongan"
                  @change="countSum(-2)"
                ></v-checkbox>
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex">
                    <div
                      class="mx-1 pa-0 d-flex rounded"
                      style="border: 1px solid grey; overflow: hidden"
                    >
                      <v-btn
                        height="100%"
                        class="rounded-0"
                        depressed
                        :dark="persen_potongan ? true : false"
                        @click="changePersen('potongan', true)"
                        :style="
                          persen_potongan
                            ? 'background:#052633;'
                            : 'background:white'
                        "
                      >
                        <v-icon small>%</v-icon>
                      </v-btn>
                      <v-btn
                        height="100%"
                        class="text-capitalize rounded-0"
                        depressed
                        :style="
                          !persen_potongan
                            ? 'background:#052633'
                            : 'background:white'
                        "
                        :dark="persen_potongan ? false : true"
                        @click="changePersen('potongan', false)"
                      >
                        <v-icon small>Rp.</v-icon>
                      </v-btn>
                    </div>
                    <v-text-field
                      style="width: 60px; background: white"
                      outlined
                      class="rounded"
                      hide-details
                      dense
                      type="number"
                      v-model="field.potongan"
                      @blur="countSum(-2)"
                      @change="countSum(-2)"
                    ></v-text-field>
                  </div>
                  <h4>Rp. {{ field.isCut ? field.potongan_txt : 0 }}</h4>
                </div>
              </div>
              <v-divider class="my-4"></v-divider> -->
              <div class="mt-2 d-flex justify-space-between">
                <h4>Total Tagihan</h4>
                <h4>Rp. {{ formatRupiah(field.total_tagihan) }}</h4>
              </div>
              <v-divider class="my-4"></v-divider>
            </div>
          </v-col>
          <v-col cols="12" md="12">
            <div class="d-flex justify-end">
              <v-btn
                min-width="200px"
                outlined
                color="#BD9028"
                class="text-capitalize mr-2"
                to="/admin/mo/event"
              >
                Batal
              </v-btn>
              <!-- <v-btn
                :disabled="
                  !loadingCreate
                    ? itemsInvoice[0].layanan &&
                      itemsInvoice[0].jenis &&
                      itemsInvoice[0].kuantitas &&
                      itemsInvoice[0].harga_satuan &&
                      itemsInvoice[0].harga_satuan
                      ? false
                      : true
                    : true
                "
                min-width="200px"
                color="#BD9028"
                class="text-capitalize mr-2"
                @click="createData(false)"
              >
                <h4 v-if="!loadingCreate" class="txt_white">Simpan</h4>
                <h4 v-if="loadingCreate" class="txt_white">Loading...</h4>
              </v-btn> -->
              <v-btn
                :disabled="
                  !loadingCreate
                    ? itemsInvoice[0].layanan &&
                      itemsInvoice[0].jenis &&
                      itemsInvoice[0].kuantitas &&
                      itemsInvoice[0].harga_satuan &&
                      itemsInvoice[0].harga_satuan
                      ? false
                      : true
                    : true
                "
                @click="sendEmail"
                min-width="200px"
                color="#052633"
                class="text-capitalize mr-2"
              >
                <h4 class="txt_white">Generate</h4>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div>
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CurrencyInput from "../../components/currencyInput/CurrencyInput.vue";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile, CurrencyInput },
  name: "createInvoiceAutomatic",
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      d_create: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      loading: true,
      dataSet: null,
      loadingCreate: false,
      menuTransaksi: false,
      menuJatuh: false,
      persen_diskon: true,
      persen_potongan: false,
      itemsInvoice: [
        {
          layanan_id: null,
          layanan: "",
          jenis: "",
          kuantitas: 0,
          harga_satuan: 0,
          diskon: 0,
          pajak: 0,
          jumlah: 0,
        },
      ],
      optLayanan: [
        { text: "Psikotes", value: "psikotes" },
        { text: "Interview", value: "interview" },
        { text: "Konsultasi", value: "konsultasi" },
      ],
      optPajak: [
        { text: "PPN", value: 11 },
        { text: "Kosong", value: 0 },
      ],
      field: {
        nama: "",
        tgl_transaksi: "",
        tgl_jatuh: "",
        id: "",
        notes: "",
        total: 0,
        total_diskon: 0,
        pilihan_potongan: "",
        diskon_tambahan: 0,
        diskon_tamhahan_txt: 0,
        sum_pajak: 0,
        total_before_cut: 0,
        isCut: false,
        potongan: 0,
        potongan_txt: 0,
        total_tagihan: 0,
      },
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Buat Invoice Event",
          disabled: true,
          href: "#",
        },
      ],
      customer: null,
      customer_id: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    formatRupiah(angka) {
      var temp_angka = angka.toString().replace(".", ",");
      var number_string = temp_angka,
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;
    },
    checkNamaClient(id_customer) {
      for (let i = 0; i < this.customer.length; i++) {
        if (this.customer[i].id == id_customer) {
          this.field.nama = this.customer[i].id;
          this.customer_id = id_customer;
        }
      }
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/invoice/event/${this.$route.params.idEvent}`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.customer = data.list_customer;
          if (this.dataSet.timeline.length > 0) {
            this.itemsInvoice = [];
          }
          this.checkNamaClient(data.data.customer_id);
          this.dataSet.timeline.forEach((item) => {
            this.itemsInvoice.push({
              layanan_id: item.step_id,
              layanan: item.step,
              jenis: "",
              kuantitas: 0,
              harga_satuan: 0,
              diskon: 0,
              pajak: 0,
              jumlah: 0,
            });
          });
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    closeDateMenu(item, type) {
      if (type == "tgl_transaksi") {
        this.menuTransaksi = false;
        this.field.tgl_transaksi == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else {
        this.menuJatuh = false;
        this.field.tgl_jatuh == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      }
    },
    addItem(idx) {
      if (idx == this.itemsInvoice.length - 1)
        this.itemsInvoice.push({
          layanan_id: null,
          layanan: "",
          jenis: "",
          kuantitas: 0,
          harga_satuan: 0,
          diskon: 0,
          pajak: 0,
          jumlah: 0,
        });
    },
    removeArray(idx) {
      this.itemsInvoice.splice(idx, 1);
      this.countSum(-2);
    },
    countSum(n) {
      let sumHarga = 0;
      let sumDiskon = 0;
      let sumPpn = 0;
      if (n != -2) {
        this.itemsInvoice[n].jumlah =
          this.itemsInvoice[n].kuantitas * this.itemsInvoice[n].harga_satuan;
      }
      this.itemsInvoice.forEach((item) => {
        sumHarga = parseInt(item.jumlah) + parseInt(sumHarga);
        sumDiskon =
          (parseInt(item.diskon) *
            (parseInt(item.kuantitas) * parseInt(item.harga_satuan))) /
            100 +
          parseInt(sumDiskon);
        sumPpn = parseInt(item.pajak) + parseInt(sumPpn);
      });

      this.field.total = sumHarga;
      this.field.total_diskon = sumDiskon;
      if (this.persen_diskon) {
        this.field.diskon_tamhahan_txt =
          ((this.field.total - this.field.total_diskon) *
            this.field.diskon_tambahan) /
          100;
      } else {
        this.field.diskon_tamhahan_txt = this.field.diskon_tambahan;
      }
      this.field.sum_pajak =
        ((this.field.total -
          this.field.total_diskon -
          this.field.diskon_tamhahan_txt) *
          sumPpn) /
        100;

      this.field.total_before_cut =
        this.field.total -
        this.field.total_diskon -
        this.field.sum_pajak -
        this.field.diskon_tamhahan_txt;
      if (this.persen_potongan) {
        this.field.potongan_txt =
          (this.field.total_before_cut * this.field.potongan) / 100;
      } else {
        this.field.potongan_txt = this.field.potongan;
      }
      if (this.field.isCut) {
        this.field.total_tagihan =
          this.field.total_before_cut - this.field.potongan_txt;
      } else {
        this.field.total_tagihan = this.field.total_before_cut;
      }
    },
    changePersen(type, value) {
      if (type == "diskon") {
        this.persen_diskon = value;
        this.countSum(-2);
      } else {
        this.persen_potongan = value;
        this.countSum(-2);
      }
    },
    async sendEmail() {
      let isDone = await this.createData(true);
      let isNotEmptp = setInterval(() => {
        if (isDone) {
          this.d_type = "send_email_auto";
          this.d_title = "Kirim Email";
          this.inventory = this.field;
          this.line = true;
          this.d_create = true;
          clearInterval(isNotEmptp);
        }
      }, 300);
    },
    async createData(send_email) {
      this.loadingCreate = true;
      let success = false;
      let itemsInvoice = [];
      this.itemsInvoice.forEach((item) => {
        if (item.layanan_id) {
          itemsInvoice.push({
            layanan_id: item.layanan_id,
            name: item.layanan,
            jenis: item.jenis,
            qty: item.kuantitas,
            cost: item.harga_satuan,
            diskon: item.diskon,
            pajak: item.pajak,
            // jumlah: item.jumlah,
            // total: this.field.total,
            // total_diskon: this.field.total_diskon,
            // diskon_tambahan: this.field.diskon_tambahan,
            // diskon_tamhahan_txt: this.field.diskon_tamhahan_txt,
            // sum_pajak: this.field.sum_pajak,
            // total_before_cut: this.field.total_before_cut,
            // potongan: this.field.potongan,
            // potongan_txt: this.field.potongan_txt,
          });
        }
      });
      let data = {
        body: {
          klien_costumer_id: this.customer_id,
          due_date: this.field.tgl_jatuh,
          note: this.field.notes,
          status: 0,
          array_json: itemsInvoice,
          payment_method_id: 1,
          tgl_transaksi: this.field.tgl_transaksi,
          persen_diskon: this.persen_diskon,
          diskon: this.field.diskon_tambahan,
          persen_potongan: this.persen_potongan,
          potongan: this.field.potongan,
          is_cut: this.field.isCut,
          total_tagihan: this.field.total_tagihan,
        },

        path: `sysadmin/admin/invoice`,
      };
      await this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loadingCreate = false;
          if (data.status != "success") {
            if (data.data) {
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            } else {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
          } else {
            success = true;
            this.loadingCreate = false;
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          }
        })
        .catch((e) => {
          this.loadingCreate = false;
          if (e.data) {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          } else {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
        });
      if (send_email && success) {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.v-data-table tbody tr:nth-child(even) {
  background-color: white !important;
}
.parent {
  width: 60%;
}
@media (max-width: 960px) {
  .parent {
    width: 100%;
  }
}
</style>
